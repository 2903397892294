export class ClsDashboard1 {
    TB_EMP_MST_PERSONALDTLID: string = ''
    TB_EMP_MST_FAMILYDTLID: string = ''
    TB_EMP_MST_NOMINEEID: string = ''
    EMPLOYEE_NAME: string = ''
    F_H_NAME: string = ''
    EMAIL: string = ''
    CREATEDBY: string = ''
    CREATEDON: string = ''
    CANCEL: string = ''
    ACTIVE: string = ''
    DOB: string = ''
    DDO: string = ''
    UNIT: string = ''
    DESIGNATION: string = ''
    DEDUCTION: string = ''
    WITHDRAWAL: string = ''
    OPENING_BAL: string = ''
    GPF_ACC_NO: string = ''
    NO_CONTRACTS: string = ''
    CURRENT_PREMIUM: string = ''
    SURRENDER_VALUE: string = ''
    SI_POLICY_NO: string = ''
    SUM_ASSURED: string = ''
    GPA_PREMIUM: string = ''
    GPA_PROPOSAL_NO: string = ''
    GPA_LAST_DEDUCTION: string = ''
    GPA_LAST_DEDUCTION_YEAR: string = ''
    GPA_APP_NO: string = ''
    MEDICLAIM_NO: string = ''
    MEDICLAIM_AMOUNT: string = ''
    GPF_BAG: string = ''
    SI_BAG: string = ''
    APP_BAG: string = ''
    CONTACTNO: string = ''
    OFFICE: string = ''
    NOMINEE_RELATIONSHIP:string=''
    NOMINEE_NAME:string=''
    NOMINEE_DOB:string=''
    SHARE_PERCENTAGE:string=''
    SI:string=''
    GPF:string=''
    SCHEME:string=''
    APPLICATIONNO:string=''
    APPLICATION_DATE:string=''
    DOCUMENT_NAME:string=''
    E_BAG_FLAG:string=''
    IS_SCHEME:string=''
    PM_ID:String=''
    FILE_PATH:String=''

    SI_DEBIT_COUNT:string=''
    SI_DEBIT_AMOUNT:string=''
    LAST_SI_DEBIT_AMOUNT:string=''
    LAST_SI_DEBIT_DATE:string=''
    LAST_SI_DEBIT_VOCHERNO:string=''

    GPF_DEBIT_COUNT:string=''
    GPF_DEBIT_AMOUNT:string=''
    LAST_GPF_DEBIT_AMOUNT:string=''
    LAST_GPF_DEBIT_DATE:string=''
    LAST_GPF_DEBIT_VOCHERNO:string=''

     //----Service Detail----//
     UNIT_ID:string=''
     DEPARTMENT_ID:string=''
     EMPLOYEE_STATUS:string=''
     CATEGORY_ID:string=''
     SAL_BUDGET_HEAD:string=''
     DATE_OF_APPOINTMENT:string=''
     CURR_OFF_JOIN_DATE:string=''
     BASIC_PAY:string=''
     DDO_ID:string=''
     DESIGNATION_ID:string=''
     OFFICE_ID:string=''
     PAID_AMOUNT_LOAN:string=''
}

export class ClsDashboard {
AADHAR: string = ''
AADHAR_ID: string = ''
ACTIVE: string = ''
APP_BAG: string = ''
CANCEL: string = ''
CONTACTNO: string = ''
CREATEDBY: string = ''
CREATEDON: string = ''
CURRENT_FIN_GPF_DEBIT: string = ''
CURRENT_PREMIUM: string = ''
DDO: string = ''
DEDUCTION: string = ''
DEPARTMENT_NAME: string = ''
DESIGNATION: string = ''
DOB: string = ''
EMAIL: string = ''
EMPLOYEE_ID: string = ''
EMPLOYEE_NAME: string = ''
E_OTHERBAG: string = ''
E_PASSBOOKBAG: string = ''
F_H_NAME: string = ''
GENDER: string = ''
GPA_APP_NO: string = ''
GPA_LAST_DEDUCTION: string = ''
GPA_LAST_DEDUCTION_YEAR: string = ''
GPA_PREMIUM: string = ''
GPA_PROPOSAL_NO: string = ''
GPF_ACCOUNT_NO: string = ''
GPF_ACC_NO: string = ''
GPF_BAG: string = ''
GPF_BALANCE: string = ''
GPF_DEBIT_AMOUNT: string = ''
GPF_DEBIT_COUNT: string = ''
GPF_DEDUCTIONS: string = ''
GPF_INTR: string = ''
GPF_OPENING_BALANCE: string = ''
GPF_TOTAL_DED: string = ''
GPF_WITHDRAWAL: string = ''
IS_GPF_NPS: string = ''
IS_SCHEME: string = ''
IS_SEARCH: string = ''
LAST_GPF_DEBIT_AMOUNT: string = ''
LAST_GPF_DEBIT_DATE: string = ''
LAST_GPF_DEBIT_VOCHERNO: string = ''
LAST_SI_DEBIT_AMOUNT: string = ''
LAST_SI_DEBIT_DATE: string = ''
LAST_SI_DEBIT_VOCHERNO: string = ''
MEDICLAIM_AMOUNT: string = ''
MEDICLAIM_NO: string = ''
NOMINEE_DOB: string = ''
NOMINEE_NAME: string = ''
NO_CONTRACTS: string = ''
OPENING_BAL: string = ''
PM_ID: string = ''
RELATIONSHIP: string = ''
SCHEME_NAME: string = ''
SERVICE_STATUS: string = ''
SHARE_PERCENTAGE: string = ''
SI_BAG: string = ''
SI_CURRENT_PREMIUM: string = ''
SI_DEBIT_AMOUNT: string = ''
SI_DEBIT_COUNT: string = ''
SI_NO_OF_CONTRACT: string = ''
SI_POLICY_NO: string = ''
SI_SUM_ASSURED: string = ''
SI_SURRENDER_AMOUNT: string = ''
SUM_ASSURED: string = ''
SURRENDER_VALUE: string = ''
TB_EMP_MST_FAMILYDTLID: string = ''
TB_EMP_MST_PERSONALDTLID: string = ''
UNIT: string = ''
UNITID: string = ''
USERNAME: string = ''
WITHDRAWAL: string = ''
EMPLOYEE_TYPE: string = ''
CLAIMEDDTL:string=''

}